import React from 'react';

import { colors } from '../../styles/atoms/colors';

import CallToAction from '../../molecules/CallToAction/CallToAction';
import ClickableElement from '../../molecules/ClickableElement/ClickableElement';

import Img from '../../utils/OptimizedImage';

import Hex from './assets/Hex';

import {
  Heading,
  HexContainer,
  ImageContainer,
  Subhead,
  Wrapper,
} from './styles/AnimatedHexCard.styled';

const AnimatedHexCard = ({
  heading,
  subhead,
  link,
  cardImage,
  accentColor,
}) => {
  const color = accentColor ? colors[accentColor][50] : colors.gray[50];

  return (
    <Wrapper>
      {link?.url && <ClickableElement url={link?.url} />}
      {cardImage && (
        <ImageContainer color={color}>
          <Img
            image={cardImage?.image?.gatsbyImageData}
            alt={cardImage?.internalName}
            src={cardImage?.image?.url}
            title={cardImage?.image?.title}
          />
        </ImageContainer>
      )}
      {heading && <Heading>{heading}</Heading>}
      {subhead && <Subhead dangerouslySetInnerHTML={{ __html: subhead }} />}
      <CallToAction
        variant="linkGray"
        icon="right"
        link={link?.url}
        value={link.label}
        size="lg"
      />
      <HexContainer>
        <Hex color={color} />
      </HexContainer>
    </Wrapper>
  );
};

export default AnimatedHexCard;
